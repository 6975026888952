import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'
import ITBBookingAppointment from '../../components/PressRelease/ITBBookingAppointment'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/galeria-launch_1_1_en.png'
import ImageDE from '../../images/news/galeria-launch_1_1_de.png'

import { newsList } from '../../components/NewsPage/data'

/* eslint-disable max-len */
const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsGaleriaLaunch)}
        imageAlt={formatMessage(messages.TitleNewsGaleriaLaunch)}
        description={formatMessage(metaDescription.NewsGaleriaLaunchDescription)}
        image={locale === 'de' ? ImageDE : Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.galeriaLaunch}
        articleLinks={{
          tsbo: (
            <Link to="/products/quickstart/">
              TravelSandbox®
            </Link>
          ),
          ta: (
            <a href='https://www.touristik-aktuell.de/nachrichten/reisevertrieb/news/datum/2025/02/14/eniyan-praesentiert-neue-plattform-fuer-reisebueros/' target="_blank" rel="noopener noreferrer">https://www.touristik-aktuell.de/nachrichten/reisevertrieb/news/datum/2025/02/14/eniyan-praesentiert-neue-plattform-fuer-reisebueros/</a>
          ),
          fvw: (
            <a href='https://www.fvw.de/touristik/technologie/vertriebsplattform-mit-freshcells-technik-eniyan-wave-will-bueros-gegen-check24-und-co-staerken-250066' target="_blank" rel="noopener noreferrer">https://www.fvw.de/touristik/technologie/vertriebsplattform-mit-freshcells-technik-eniyan-wave-will-bueros-gegen-check24-und-co-staerken-250066</a>
          ),
          r9: (
            <a href='https://www.reisevor9.de/marketing-digitales/eniyan-stellt-neue-plattform-fuer-reisebueros-vor' target="_blank" rel="noopener noreferrer">https://www.reisevor9.de/marketing-digitales/eniyan-stellt-neue-plattform-fuer-reisebueros-vor</a>
          ),
          galeria: (
            <a href='https://www.galeria-reisen.de/' target="_blank" rel="noopener noreferrer">www.galeria-reisen.de</a>
          ),
          urlaub: (
            <a href='https://www.urlaub.de/' target="_blank" rel="noopener noreferrer">www.urlaub.de</a>
          )
        }}
        addon={<ITBBookingAppointment />}
      />
    </Layout>
  )
}

export default IndexPage
